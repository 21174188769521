
.btn-parent {
    display: block;
    position: relative;
    cursor: pointer;
}

.btn-parent:hover .btn-frame {
    display: block;
}
.btn-content {
    @apply text-main;
    width: 100%;
    height: 100%;

    padding: 10px 20px 15px 20px;

    display: flex;justify-content: center;align-items: center;
    text-align: center;
}

.btn-frame {
    @apply bg-active-border-color;

    display: none;

    position: absolute;
    top: calc(-1 * var(--border-size));
    left: calc(-1 * var(--border-size));

    width: calc(100% + var(--border-size) * 2);
    height: calc(100% + var(--border-size) * 2);

}

.btn-shadow {
    @apply bg-shadow;
    position: absolute;
    top: var(--shadow-size);
    left: 0;

    width: 100%;
    height: 100%;

}

.btn-highlight {
    @apply bg-highlight-color bg-blend-multiply;
    position: absolute;
    bottom: calc(var(--corner-clip-size));
    left: 0;

    width: 100%;
    height: 6px;

    clip-path: polygon(
            0 0,
            calc(var(--corner-clip-size) + 2px) 0,
            calc(var(--corner-clip-size) + 2px) calc(100% - 2px),
            calc(100% - var(--corner-clip-size) - 2px) calc(100% - 2px),
            calc(100% - var(--corner-clip-size) - 2px) 0,
            100% 0,
            100% 2px,
            calc(100% - var(--corner-clip-size)) 2px,
            calc(100% - var(--corner-clip-size)) 100%,
            var(--corner-clip-size) 100%,
            var(--corner-clip-size) 2px,
            0 2px
    );
}

.btn-thick {
    @apply bg-thick-color;
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: calc(var(--corner-clip-size) + 3px);

    clip-path: polygon(
            0 0,
            var(--corner-clip-size) 0,
            var(--corner-clip-size) 3px,
            calc(100% - var(--corner-clip-size)) 3px,
            calc(100% - var(--corner-clip-size)) 0,
            100% 0,
            100% 3px,
            calc(100% - var(--corner-clip-size)) 3px,
            calc(100% - var(--corner-clip-size)) 100%,
            var(--corner-clip-size) 100%,
            var(--corner-clip-size) 3px,
            0 3px
    );
}


.btn-parent-disabled:hover .btn-frame {
    display: none;
}

.btn-parent-disabled .btn-content {
    background-color: #4a4a4e;
}

.btn-parent-disabled .btn-highlight {
    display: none;
}

.btn-parent-disabled .btn-thick {
    opacity: 0.8;
}
