@tailwind base;
@tailwind components;
@tailwind utilities;

/* Basic Var */
:root {
    --corner-clip-size: 4px;
    --border-size: 2px;
    --shadow-size: 4px;
    --divider-border: 2px solid rgba(255,255,255,0.15);

    --corner-clip-shape: polygon(
        /* left top */
            0 var(--corner-clip-size),
            var(--corner-clip-size) var(--corner-clip-size),
            var(--corner-clip-size) 0,
                /* right top */
            calc(100% - var(--corner-clip-size)) 0,
            calc(100% - var(--corner-clip-size)) var(--corner-clip-size),
            100% var(--corner-clip-size),
                /* right bottom */
            100% calc(100% - var(--corner-clip-size)),
            calc(100% - var(--corner-clip-size)) calc(100% - var(--corner-clip-size)),
            calc(100% - var(--corner-clip-size)) 100%,
                /* left bottom */
            var(--corner-clip-size) 100%,
            var(--corner-clip-size) calc(100% - var(--corner-clip-size)),
            0 calc(100% - var(--corner-clip-size))
    );
    font-family: zpix, sans-serif;
}

@font-face {
    font-family: zpix;
    src: url("/public/fonts/zpix.ttf");
}

/* Content Limit */
.content-limit {
    @apply mx-auto max-w-5xl;
}
.content-padding {
    @apply px-4 lg:px-2;
}

.clay-text-shadow {
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.3);
}
.clay-heavy-text-shadow {
    text-shadow: 0 0 30px #000, 0 0 12px #000, 0 2px 0 rgba(0, 0, 0, 0.3);
}

.clay-title {
    font-size: 36px;
    line-height: 36px;
    text-shadow: 0 4px 0 rgba(0, 0, 0, 0.3);
}

.corner-clip {
    clip-path: var(--corner-clip-shape);
}

.corner-clip-content {
    --corner-clip-size: 6px;

    --corner-clip-shape: polygon(
        /* left top */
            0 var(--corner-clip-size),
            var(--corner-clip-size) var(--corner-clip-size),
            var(--corner-clip-size) 0,
                /* right top */
            calc(100% - var(--corner-clip-size)) 0,
            calc(100% - var(--corner-clip-size)) var(--corner-clip-size),
            100% var(--corner-clip-size),
                /* right bottom */
            100% calc(100% - var(--corner-clip-size)),
            calc(100% - var(--corner-clip-size)) calc(100% - var(--corner-clip-size)),
            calc(100% - var(--corner-clip-size)) 100%,
                /* left bottom */
            var(--corner-clip-size) 100%,
            var(--corner-clip-size) calc(100% - var(--corner-clip-size)),
            0 calc(100% - var(--corner-clip-size))
    );

    clip-path: var(--corner-clip-shape);
}
